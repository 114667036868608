@import url("https://fonts.googleapis.com/css2?family=Nunito:wght@300;700&display=swap");
*,
*::after,
*::before {
  margin: 0;
  padding: 0;
  box-sizing: inherit; }

html {
  font-size: 62.5%;
  scroll-behavior: smooth; }
  @media (max-width: 768px) {
    html {
      font-size: 50%; } }

body {
  box-sizing: border-box;
  font-family: "Nunito", sans-serif;
  width: 100%;
  height: 100%;
  margin: 0px;
  padding: 0;
  background-color: #121212;
  color: rgba(255, 255, 255, 0.87);
  font-size: 1rem;
  line-height: 1.5rem;
  font-weight: 400;
  text-rendering: optimizelegibility;
  transition: background-color 0.5s ease; }

h1 {
  line-height: 4rem;
  background-color: #4158d0;
  background-image: linear-gradient(43deg, #a162e8 0%, #f7ce68 46%, #8e9ffa 100%);
  background-clip: border-box;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent; }
  h1.light {
    background-image: linear-gradient(43deg, #2c10c9 0%, #274b81 46%, #0e323d 100%); }

h2 {
  line-height: 4rem;
  background-color: #4158d0;
  background-image: linear-gradient(43deg, #a162e8 0%, #f7ce68 46%, #8e9ffa 100%);
  background-clip: border-box;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent; }
  h2.light {
    background-image: linear-gradient(43deg, #2c10c9 0%, #274b81 46%, #0e323d 100%); }

.container {
  max-width: 1280px;
  margin: 0 auto; }

h3 {
  line-height: 3rem;
  font-size: 1.4rem;
  background-color: #8e9ffa;
  background-image: linear-gradient(45deg, #8e9ffa 0%, #f874ef 50%, #ffcc70 69%, #ffffff 100%);
  background-clip: border-box;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent; }
  h3.light {
    background-image: linear-gradient(45deg, #2c10c9 0%, #0e323d 50%, #274b81 69%, #000000 100%); }

svg {
  color: #fafafa; }

.footer {
  margin: 5rem 0;
  text-align: center;
  padding: 2rem 0;
  font-size: 1.5rem;
  background-color: #999;
  position: absolute;
  left: 0;
  width: 100%; }

/*

0 - 600px:          Phone
600px - 900px       Table Portrait
900px - 1200px      Table Landscape
[1200px - 1800px]   Desktop Normal Styles
1800px +            Big Desktop

1em = 16px

ORDER: Base + Typography > Generar Layout + Grid > Page Layout > Components

*/
.nav {
  position: fixed;
  background-color: transparent;
  top: 0;
  left: 0;
  right: 0;
  transition: all 0.3s ease-in-out;
  z-index: 10; }
  .nav__logo img {
    height: 100px;
    width: 100px; }
    .nav__logo img:hover {
      filter: brightness(110%); }
    @media (max-width: 768px) {
      .nav__logo img {
        height: 60px;
        width: 60px; } }
  .nav i {
    font-size: 3rem;
    margin-right: 3rem;
    cursor: pointer; }

.nav .container {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 20px 0;
  transition: all 0.3s ease-in-out; }

.nav ul {
  display: flex;
  list-style: none;
  align-items: center;
  justify-content: center;
  font-size: 2rem; }

.nav a {
  color: white;
  text-decoration: none;
  padding: 7px 15px;
  transition: all 0.3s ease-in-out; }
  .nav a.light {
    color: #121212; }

.nav.active {
  background-color: #222;
  box-shadow: 0 2px 10px rgba(0, 0, 0, 0.3); }

.nav.active.light {
  background-color: white; }
  .nav.active.light a {
    color: black; }

.nav.active a {
  color: white; }

.nav.active .container {
  padding: 10px 0; }

.nav a.current,
.nav a:hover {
  color: #c0392b; }

/* Call to Action Button */
.cta-btn {
  font-family: inherit;
  font-size: 2rem;
  display: inline-block;
  position: relative;
  padding: 1rem 2rem;
  margin-right: 1rem;
  font-weight: bold;
  line-height: 1;
  z-index: 1;
  transition: all cubic-bezier(0.19, 1, 0.22, 1) 0.6s; }
  @media (max-width: 37.5em) {
    .cta-btn {
      font-size: 1.5rem; } }
  .cta-btn--contact {
    color: #a162e8;
    -webkit-text-fill-color: #a162e8;
    border: 2px solid transparent;
    border-image: linear-gradient(135deg, #4158d0 0%, #a162e8 100%);
    border-image-slice: 1; }
    @supports (-webkit-background-clip: text) or (background-clip: text) {
      .cta-btn--contact {
        background-image: linear-gradient(135deg, #4158d0 0%, #a162e8 100%);
        -webkit-background-clip: text;
        -webkit-text-fill-color: transparent;
        color: transparent; } }
    @media screen and (-ms-high-contrast: active), screen and (-ms-high-contrast: none) {
      .cta-btn--contact {
        color: #a162e8 !important; }
        .cta-btn--contact:hover {
          color: #fff !important; } }
    @media (max-width: 37.5em) {
      .cta-btn--contact {
        background-image: none;
        border: 2px solid #a162e8;
        -webkit-text-fill-color: #a162e8; } }
    .cta-btn--contact::after {
      background-image: linear-gradient(135deg, #4158d0 0%, #a162e8 100%); }
      @media (max-width: 37.5em) {
        .cta-btn--contact::after {
          background-image: none; } }
    .cta-btn--contact:hover {
      -webkit-text-fill-color: #fff;
      text-decoration: none; }
      @media (max-width: 37.5em) {
        .cta-btn--contact:hover {
          -webkit-text-fill-color: #a162e8; } }
      .cta-btn--contact:hover::after {
        width: 100%; }
  .cta-btn::after {
    content: '';
    display: block;
    position: absolute;
    width: 0px;
    height: 100%;
    left: 0;
    bottom: 0;
    z-index: -1;
    transition: all cubic-bezier(0.19, 1, 0.22, 1) 0.3s; }

/* Hero Style */
.cta-btn--hero {
  color: #a162e8;
  -webkit-text-fill-color: #a162e8;
  border: 2px solid transparent;
  border-image: linear-gradient(135deg, #4158d0 0%, #a162e8 100%);
  border-image-slice: 1; }
  @supports (-webkit-background-clip: text) or (background-clip: text) {
    .cta-btn--hero {
      background-image: linear-gradient(135deg, #4158d0 0%, #a162e8 100%);
      -webkit-background-clip: text;
      -webkit-text-fill-color: transparent;
      color: transparent; } }
  .cta-btn--hero::after {
    background-image: linear-gradient(135deg, #4158d0 0%, #a162e8 100%); }
    @media (max-width: 37.5em) {
      .cta-btn--hero::after {
        background-image: none; } }
  .cta-btn--hero:hover {
    -webkit-text-fill-color: #fff;
    text-decoration: none; }
    @media (max-width: 37.5em) {
      .cta-btn--hero:hover {
        -webkit-text-fill-color: #a162e8; } }
    .cta-btn--hero:hover::after {
      width: 100%; }

/* Resume Style */
.cta-btn--resume {
  color: #fff;
  border: 2px solid #fff; }
  .cta-btn--resume::after {
    background: #fff; }
  .cta-btn--resume:hover {
    color: #a162e8;
    text-decoration: none; }
    .cta-btn--resume:hover::after {
      width: 100%; }

/* Arrow Button */
.up i {
  color: #272727; }

.project {
  display: flex;
  margin: 5rem 0;
  align-items: center; }
  @media (max-width: 1200px) {
    .project {
      flex-direction: column;
      background-color: rgba(255, 255, 255, 0.1);
      max-width: 900px;
      margin: 5rem auto;
      padding: 3rem;
      border-radius: 1rem; }
      .project__image {
        order: 1;
        margin-top: 5rem; }
      .project__info {
        text-align: center; }
        .project__info--title {
          padding: 1rem 0; }
        .project__info--tools {
          justify-content: center; }
        .project__info--links-github p,
        .project__info--links-live p {
          opacity: 1 !important;
          transform: translateX(5rem) !important; } }
  @media (max-width: 768px) {
    .project {
      margin: 5rem 1rem; } }
  .project__image {
    flex: 1; }
    .project__image img {
      width: 100%;
      border-radius: 10px; }
  .project__info {
    padding: 3rem;
    flex: 1; }
    .project__info--title {
      font-size: 3rem;
      padding: 2rem 0; }
    .project__info--desc {
      font-size: 1.5rem;
      padding: 2rem 0;
      line-height: 2rem; }
    .project__info--tools {
      list-style: none;
      font-size: 1.5rem;
      display: flex;
      flex-wrap: wrap; }
      .project__info--tools-badge {
        margin: 0.5rem;
        padding: 0.5rem 1rem;
        border: 2px solid transparent;
        border-image: linear-gradient(135deg, #4158d0 0%, #a162e8 100%);
        border-image-slice: 1; }
        .project__info--tools-badge:first-child {
          margin-left: 0; }
        .project__info--tools-badge.light {
          border: 1px solid black; }
    .project__info--links {
      display: flex;
      flex-direction: column;
      margin: 2rem 0;
      height: 3rem;
      width: 3rem; }
      .project__info--links i {
        font-size: 3.5rem;
        color: white;
        margin: 1rem 0; }
        .project__info--links i.light {
          color: black; }
      .project__info--links-github {
        position: relative; }
        .project__info--links-github:hover p {
          opacity: 1;
          transform: translate(5rem, 1rem); }
        .project__info--links-github span {
          margin-right: 0.5rem;
          transform: translateY(-2px); }
        .project__info--links-github p {
          display: flex;
          align-items: center;
          width: 100px;
          font-size: 1.5rem;
          text-decoration: none;
          color: white;
          position: absolute;
          top: 10px;
          transform: translate(-10px, 5px);
          z-index: -2;
          opacity: 0;
          transition: all 0.3s ease; }
          .project__info--links-github p.light {
            color: black; }
      .project__info--links-live {
        position: relative; }
        .project__info--links-live:hover p {
          opacity: 1;
          transform: translate(5rem, 1rem); }
        .project__info--links-live span {
          margin-right: 0.5rem;
          transform: translateY(-2px); }
        .project__info--links-live p {
          display: flex;
          width: 100px;
          font-size: 1.5rem;
          text-decoration: none;
          color: white;
          position: absolute;
          top: 10px;
          transform: translate(-10px, 5px);
          z-index: -2;
          opacity: 0;
          transition: all 0.3s ease; }
          .project__info--links-live p.light {
            color: black; }

.order2 {
  order: 2; }

.form-container {
  margin-top: 20rem;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 2rem; }
  .form-container__title {
    padding: 5rem;
    font-size: 5rem;
    color: white;
    margin-bottom: 4rem; }
  .form-container__subtitle {
    color: white;
    font-size: 2.5rem;
    margin-bottom: 2rem;
    line-height: 3rem;
    text-align: center; }
    @media (max-width: 768px) {
      .form-container__subtitle {
        font-size: 2rem; } }
    .form-container__subtitle.light {
      color: black; }

.contact-form {
  margin-top: 4rem;
  display: flex;
  flex-direction: column;
  width: 600px; }
  @media (max-width: 1200px) {
    .contact-form {
      width: 400px; } }
  @media (max-width: 480px) {
    .contact-form {
      width: 300px; } }
  .contact-form__group {
    margin-bottom: 2rem;
    transition: all 0.3s; }
    .contact-form__group:focus-within {
      transform: scale(1.1, 1.1); }
  .contact-form__label {
    font-size: 1.75em;
    padding: 5px 0;
    color: white;
    display: block;
    opacity: 0;
    transition: all 0.3s;
    transform: translateX(-50px); }
    .contact-form__label.light {
      color: black; }
  .contact-form__control {
    background-color: transparent;
    color: white;
    box-shadow: none;
    border-radius: 0;
    border-color: #ccc;
    border-style: none none solid none;
    border-radius: 3px;
    width: 100%;
    font-size: 2.25em;
    transition: all 0.6s;
    padding: 1rem 2.5rem; }
    .contact-form__control.light {
      color: black;
      border-color: #333; }
      .contact-form__control.light::placeholder {
        color: #333; }
    .contact-form__control::placeholder {
      color: #aaa; }
    .contact-form__control:focus {
      box-shadow: none;
      border-color: orange;
      outline: none; }
    .contact-form__control:invalid:focus {
      border-color: red; }
    .contact-form__control:valid:focus {
      border-color: green; }
  .contact-form__btn {
    border: none;
    border-radius: 3px;
    color: #fff;
    font-size: 3em;
    padding: 10px 50px;
    text-transform: uppercase; }
    .contact-form__btn:hover {
      filter: brightness(110%);
      cursor: pointer; }
  .contact-form__alert {
    font-size: 3rem; }

.focus {
  opacity: 1;
  transform: translateX(0px); }

textarea {
  resize: none; }

.sent {
  color: #fff; }

.g-recaptcha {
  margin: 0 auto 2rem auto; }

.drawer {
  position: fixed;
  top: -20px;
  background-color: #121212;
  height: calc(100% + 20px);
  width: 60vw;
  z-index: 100;
  display: flex;
  flex-direction: column;
  align-items: center;
  transform: translateX(-200%);
  transition: transform 0.3s ease-in-out; }
  .drawer__list {
    list-style: none;
    margin-top: 3rem;
    width: 100%;
    text-align: center; }
    .drawer__list--link {
      margin: 2rem 0;
      padding: 1rem 0;
      transition: all 0.2s; }
      .drawer__list--link a {
        text-decoration: none;
        color: white;
        font-size: 2.5rem;
        padding: 1rem 0; }
      .drawer__list--link:hover {
        background-color: white; }
        .drawer__list--link:hover a {
          color: #333; }
  .drawer__social-media {
    font-size: 3rem; }
    .drawer__social-media i {
      margin: 0 1rem;
      color: white; }

.drawer.open {
  transform: translateX(0); }

.theme-select {
  color: white;
  font-family: inherit;
  background-color: #121212;
  padding: 1rem 2rem;
  border: 2px solid transparent;
  border-image: linear-gradient(135deg, #4158d0 0%, #a162e8 100%);
  border-image-slice: 1; }
  .theme-select.light {
    color: #121212;
    background-color: #98c2ff; }
  .theme-select.nineties {
    color: rgba(255, 255, 255, 0.87);
    background-color: pink; }

.theme-switcher {
  display: flex;
  align-items: center;
  z-index: 100; }
  .theme-switcher span {
    margin-right: 10px;
    font-size: 1rem; }
  .theme-switcher__toggle-icon {
    margin-left: 10px; }
  .theme-switcher__label {
    display: inline-block;
    height: 34px;
    position: relative;
    width: 60px; }
    .theme-switcher__label input {
      display: none; }
      .theme-switcher__label input:checked + .theme-switcher__label--slider {
        background: #4158d0; }
        .theme-switcher__label input:checked + .theme-switcher__label--slider::before {
          transform: translateX(26px); }
    .theme-switcher__label--slider {
      background: #ccc;
      bottom: 0;
      cursor: pointer;
      left: 0;
      position: absolute;
      right: 0;
      top: 0;
      transition: 0.4s; }
      .theme-switcher__label--slider::before {
        background: #fff;
        bottom: 4px;
        content: '';
        height: 26px;
        left: 4px;
        position: absolute;
        transition: 0.4s;
        width: 26px; }
      .theme-switcher__label--slider.round {
        border-radius: 34px; }
        .theme-switcher__label--slider.round::before {
          border-radius: 50%; }

.about {
  margin-top: 20rem;
  border-radius: 1rem; }
  .about__title {
    text-align: center;
    font-size: 4rem; }
  .about__info-container {
    margin-top: 5rem;
    padding: 2rem;
    display: grid;
    grid-template-columns: 1fr 3fr;
    gap: 5rem; }
    @media (max-width: 1200px) {
      .about__info-container {
        grid-template-columns: 1fr; } }
    .about__info-container p {
      font-size: 2.25rem;
      line-height: 1.5; }
    .about__info-container h2 {
      font-size: 2rem;
      line-height: 1.2;
      margin-bottom: 2rem; }
    .about__info-container h3 {
      font-size: 2rem;
      line-height: 1.2;
      margin-bottom: 2rem;
      font-weight: 300; }
    .about__info-container span {
      font-weight: 700; }
    @media (max-width: 1200px) {
      .about__info-container--github-info {
        width: 50%;
        margin: 0 auto; } }
    .about__info-container--github-info img {
      width: 100%; }
  .about__story {
    width: 60%;
    font-size: 2rem;
    line-height: 3rem;
    margin: 5rem auto; }
    @media (max-width: 768px) {
      .about__story {
        width: 90%;
        font-size: 1.5rem; } }
  .about__seework {
    font-size: 2rem;
    margin: 2rem; }

.projects {
  margin-top: 20rem; }
  .projects__title {
    font-size: 4rem;
    padding: 2rem 0;
    margin-bottom: 5rem;
    text-align: center;
    background-color: #4158d0;
    background-image: linear-gradient(43deg, #a162e8 0%, #f7ce68 46%, #85ffbd 100%);
    background-clip: border-box;
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent; }

.freelance {
  margin: 20rem 0; }

.Hero {
  margin: 4rem;
  padding: 10rem 0;
  display: flex;
  flex-direction: column;
  flex-wrap: wrap; }
  @media (min-width: 768px) {
    .Hero {
      margin-top: 8rem;
      margin-bottom: 8rem; } }
  .Hero__animation {
    min-height: 600px;
    margin-left: auto;
    width: 50%; }
    @media (max-width: 1200px) {
      .Hero__animation {
        min-height: 400px; } }
    @media (max-width: 768px) {
      .Hero__animation {
        min-height: 250px; } }
    .Hero__animation--container {
      height: 100%;
      max-width: 1200px;
      margin: 0 auto; }
  .Hero__heading {
    max-width: 45rem;
    margin-bottom: auto; }
    @media (max-width: 768px) {
      .Hero__heading {
        max-width: 35rem; } }
    .Hero__heading--name {
      font-size: 7rem;
      line-height: 1;
      font-weight: bold; }
      @media (max-width: 768px) {
        .Hero__heading--name {
          font-size: 4rem; } }
    .Hero__heading--desc {
      font-size: 1.5rem;
      letter-spacing: 0.025em;
      line-height: 1.625;
      margin-bottom: 2rem; }

.patterns {
  background-image: url("./assets/bg-effects.png");
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  position: relative; }
  .patterns__light {
    background-image: url("./assets/clouds.png");
    background-position: top;
    background-size: contain;
    position: relative; }
    @media (max-width: 768px) {
      .patterns__light {
        background-position: top; } }

.hero-button-wrapper {
  display: flex; }
